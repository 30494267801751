import React, { useRef, useState } from "react";
import { GitHubRepository, EnvVarInput } from "@back4app2/sdk/lib/Back4app2";
import { ContainerType, ENV_VAR_FORM } from "../types/containerTypes";

import styles from '../assets/styles/CreateContainerForm.module.css';

import ToggleSwitchCheckbox from "../components/FormInputField/ToggleSwitchCheckbox/ToggleSwitchCheckbox";
import TextInput from "../components/FormInputField/TextInput/TextInput";
import Button from "../components/Button";
import MessageContainer from "../components/IntegrateGithub/MessageContainer/MessageContainer";

import { ReactComponent as PlusIcon } from '../assets/images/plus-icon.svg';
import { ReactComponent as Spinner } from "../assets/images/loading-spinner.svg";
import { ReactComponent as ErrorIcon } from "../assets/images/error-icon.svg";
import { ReactComponent as TrashIcon } from "../assets/images/trash-icon.svg";
import { ReactComponent as VisibilityIcon } from "../assets/images/visibility-icon.svg";
import { ReactComponent as VisibilityOffIcon } from "../assets/images/visibility-off-icon.svg";

import CollapsibleComponent from "../components/CollapsibleComponent";

interface ContainerFormProps {
  isCreatingApp: boolean,
  creatingAppErrorMessage: string,
  onCancel: () => void,
  onSubmit: (container: ContainerType) => void,
  selectedRepository: GitHubRepository | undefined
}
interface ContainerFormType {
  containerName: {
    value: string,
    isValid: Boolean,
    isRequired: Boolean,
    errorMsg: string
  },
  branchName: {
    value: string,
    isValid: Boolean,
    isRequired: Boolean,
    errorMsg: string
  },
  rootDirectoryPath: {
    value: string,
    isValid: Boolean,
    isRequired: Boolean,
    errorMsg: string
  },
  autoDeploy: {
    value: boolean,
  },
  environmentVars: ENV_VAR_FORM[],
  exposedPort: {
    value: string,
    isValid: Boolean,
    isRequired: Boolean,
    errorMsg: string
  },
  healthCheckEndpoint: {
    value: string,
    isValid: Boolean,
    isRequired: Boolean,
    errorMsg: string
  },
}

const INITIAL_STATE: ContainerFormType = {
  containerName: {
    value: '',
    isValid: true,
    isRequired: true,
    errorMsg: ''
  },
  branchName: {
    value: '',
    isValid: true,
    isRequired: true,
    errorMsg: ''
  },
  rootDirectoryPath: {
    value: './',
    isValid: true,
    isRequired: false,
    errorMsg: ''
  },
  autoDeploy: { value: true },
  environmentVars: [],
  exposedPort: {
    value: '',
    isValid: true,
    isRequired: false,
    errorMsg: ''
  },
  healthCheckEndpoint: {
    value: '/',
    isValid: true,
    isRequired: false,
    errorMsg: ''
  }
}

const CreateContainerForm = ({ isCreatingApp, creatingAppErrorMessage, onCancel, onSubmit, selectedRepository }: ContainerFormProps) => {
  const [container, setContainer] = useState<ContainerFormType>({...INITIAL_STATE, branchName: {...INITIAL_STATE.branchName, value: selectedRepository?.defaultBranch || ''}});
  const nameRef = useRef<HTMLInputElement>(null);

  const handleOnChangeEnvVars = (id: string, newValue: string, type: 'name' | 'value') => {
    const newEnvVariableList = container.environmentVars.map(env => {
      if (id === env.id && type === 'name') {
        // use only uppercase letters, underscore (_) and numbers.
        // Ref: https://stackoverflow.com/a/2821183/11070995
        let isValid = !!newValue.match(/(^[A-Z_])[A-Z0-9_]+$/);
        let errorMsg = '';
        if (!isValid) {
          errorMsg = 'Substitution variables must begin with an underscore (_) or uppercase letter and use only uppercase letters and numbers';
        } else {
          errorMsg = '';
        }
        return { ...env, name: { value: newValue, isValid, errorMsg }} as ENV_VAR_FORM;
      } else if (id === env.id && type === 'value') {
        let isValid = newValue !== '';
        return { ...env, value: { ...env.value ,value: newValue, isValid }} as ENV_VAR_FORM;
      }
      return env;
    });
    setContainer(prev => ({ ...prev, environmentVars: newEnvVariableList }));
  }

  const handleAddEnviromentVar = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    // validate if already empty entry present
    const emptyVarIdx = container.environmentVars.findIndex(env => env.name.value === '');
    let newEnvVariableList: ENV_VAR_FORM[] = [...container.environmentVars];
    if (emptyVarIdx !== -1) {
      let newEnvVar: ENV_VAR_FORM = { ...container.environmentVars[emptyVarIdx] };
      newEnvVar.name.isValid = false;
      newEnvVar.name.errorMsg = 'A variable name is must!'
      newEnvVariableList.splice(emptyVarIdx, 1, newEnvVar);
    } else {
      const newEnvVar: ENV_VAR_FORM = {
        id: Math.random().toString(16).slice(2), name: { isValid: true, value: '', errorMsg: '' }, value: { isValid: true, value: '', showValue: false }
      } 
      newEnvVariableList = [...container.environmentVars, newEnvVar ];
    }
    setContainer(prev => ({ ...prev, environmentVars: newEnvVariableList }));
  }

  const handleRemoveEnviromentVar = (id: string) => {
    const newEnvVariableList = container.environmentVars.filter(env => env.id !== id);
    setContainer(prev => ({ ...prev, environmentVars: newEnvVariableList }));
  }

  const toggleEnvVariableVisibilty = (id: string) => {
    const newEnvVariableList = container.environmentVars.map(env => {
      if (env.id === id) {
        return { ...env, value: {...env.value, showValue: !env.value.showValue} }
      }
      return env;
    });
    setContainer(prev => ({ ...prev, environmentVars: newEnvVariableList }));
  }

  const onChangeContainerName = (newValue: string) => {
    // validation
    let isValid = true, errorMsg = '';
    if (newValue.trim() === '') {
      isValid = false;
      errorMsg = 'Container name is required!'
    }
    setContainer((prev => ({ ...prev, containerName: { ...prev.containerName, value: newValue, isValid, errorMsg }})));
  }

  const onChangeBranchName = (newValue: string) => {
    // validation
    let isValid = true, errorMsg = '';
    if (newValue.trim() === '') {
      isValid = false;
      errorMsg = 'Repositary branch name is required!'
    }
    setContainer((prev => ({ ...prev, branchName: { ...prev.branchName, value: newValue, isValid, errorMsg }})));
  }

  const onChangeHealthCheckEndpoint = (newValue: string) => {
    // validation
    let isValid = true, errorMsg = '';
    if (newValue[0] !== '/') {
      newValue = `/${newValue}`;
    }
    setContainer((prev => ({ ...prev, healthCheckEndpoint: { ...prev.healthCheckEndpoint, value: newValue, isValid, errorMsg }})));
  }

  const onChangeRootDirectorPath = (newValue: string) => {
    // validation
    let isValid = true, errorMsg = '';
    newValue = newValue.trim();
    if (!newValue.startsWith('./')) {
      if (newValue.startsWith('.') || newValue.startsWith('/')) {
        newValue = newValue.substring(1);
      }
      newValue = `./${newValue}`;
    }
    setContainer((prev => ({ ...prev, rootDirectoryPath: { ...prev.rootDirectoryPath, value: newValue, isValid, errorMsg }})));
  }

  const onChangePort = (newValue: string) => {
    // validation
    let isValid = true, errorMsg = '';
    let val = +newValue;
    if (isNaN(val) || !(Number.isInteger(val) && val > 0 && val <= 65535)) {
      isValid = false;
      errorMsg = 'Please provide a valid port between 0 to 65535.'
      newValue = '';
    }
    setContainer((prev => ({ ...prev, exposedPort: { ...prev.exposedPort, value: newValue, isValid, errorMsg }})));
  }

  const handleFormSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    // validate container name
    if (container.containerName.value === '') {
      nameRef.current?.focus();
      let isValid = false;
      let errorMsg = 'Container name is required!'
      setContainer((prev => ({ ...prev, containerName: { ...prev.containerName, isValid, errorMsg }})))
      return;
    }
    // form containerType value
    let envVars: EnvVarInput[] = container.environmentVars.filter(env => env.name.value !== '').map(env => ({key: env.name.value, value: env.value.value }));
    const containerPayload: ContainerType = {
      name: container.containerName.value,
      selectedRepo: selectedRepository,
      branch: container.branchName.value,
      rootDirPath: container.rootDirectoryPath.value,
      autoDeploy:  container.autoDeploy.value,
      envVars,
      exposedPort: +container.exposedPort.value,
      healthCheckEndpoint: container.healthCheckEndpoint.value
    }
    onSubmit(containerPayload);
  }

  if (isCreatingApp) {
    return <div className="pt-[5%]">
      <MessageContainer msgTitle="creating app" primaryIcon={<Spinner className="animate-spin text-old-blue" width="24px" height="24px" />} />
    </div>
  }

  if (creatingAppErrorMessage) {
    return <MessageContainer 
      msgTitle={creatingAppErrorMessage} 
      primaryIcon={<ErrorIcon />} 
      msgDesc={"Please try again."} 
    />
  }

  return (
    <form className={styles.containerForm}>
      <div className={styles.inputFields}>
        <TextInput
          value={container.containerName.value} 
          label="App Name" 
          name={"name"} 
          onChange={(e) => onChangeContainerName(e.target.value)} 
          placeholderText="Your App name"
          ref={nameRef}
          isError={!container.containerName.isValid}
          errorMessage={container.containerName.errorMsg}
          isRequired={container.containerName.isRequired}
        />
        <div className="mt-6"></div>
        <CollapsibleComponent title="Build and Deploy" open={false}>
          <div className="space-y-6">
            <TextInput 
              value={container.branchName.value} 
              label="Branch" 
              name="branch" 
              onChange={(e) => onChangeBranchName(e.target.value)} 
              placeholderText="Branch"
              infoText="The repository branch used for your App."
              isError={!container.branchName.isValid}
              errorMessage={container.branchName.errorMsg}
            />
            <TextInput 
              value={container.rootDirectoryPath.value} 
              label="Root directory" 
              name="rootDirPath" 
              onChange={(e) => onChangeRootDirectorPath(e.target.value)} 
              placeholderText="./"
              infoText="Defaults to repository root. When you specify a root directory that is different from your repository root, Back4app runs all your commands in the specified directory and ignores changes outside the directory."
              isError={!container.rootDirectoryPath.isValid}
              errorMessage={container.rootDirectoryPath.errorMsg}
            />
            <ToggleSwitchCheckbox value={container.autoDeploy.value} onChange={() => setContainer(prev => ({ ...prev, autoDeploy: { value: !prev.autoDeploy.value} }))} labelText="Autodeploy" description={`Automatic deploy on every push to your repository or changes to your App? Select "No" to handle your deploys manually.`} />
            <TextInput 
              value={`${container.exposedPort.value}`} 
              label="Port" 
              name="exposedPort" 
              onChange={(e) => onChangePort(e.target.value)} 
              placeholderText="8080"
              infoText="Defaults to dockerfile exposed port. When you specify a port that is different from your dockerfile exposed port, Back4app exposes that port."
              isError={!container.exposedPort.isValid}
              errorMessage={container.exposedPort.errorMsg}
            />
          </div>
        </CollapsibleComponent>
        {/* <div className="h-4 border-t-light-blue border-opacity-20 border-t"/> */}
        <CollapsibleComponent title="Enviroment Variables" open={false} helpText="Optional" infoText="Use environment variables to store API keys and other configuration values and secrets.">
          <div className="text-sm mb-6 ml-0.5">Your App will have access to these variables at both build time and runtime.</div>
          {/* Enviroment variable list */}
          {container.environmentVars.map((enviromentVar) => {
            const { id, name, value } = enviromentVar;
            return(
              <div className="flex justify-between items-start mb-8" key={id}>
                <div className={styles.inputGroupDiv + ' flex-1'}>
                  <TextInput 
                    value={name.value} 
                    label="Name" 
                    name={`env-name-${id}`} 
                    onChange={(e) => handleOnChangeEnvVars(id, e.target.value.trim(), 'name')} 
                    placeholderText="EXAMPLE_NAME"
                    isError={!name.isValid}
                    errorMessage={name.errorMsg}
                  />
                  <TextInput 
                    type={value.showValue ? 'text' : 'password'}
                    value={value.value} 
                    label="Value" 
                    name={`env-value-${id}`} 
                    onChange={(e) => handleOnChangeEnvVars(id, e.target.value, 'value' )} 
                    placeholderText="A1B2C3D4E5F6"
                    endAdornment={<span className="cursor-pointer" onClick={() => toggleEnvVariableVisibilty(id)}>{value.showValue ? <VisibilityOffIcon /> : <VisibilityIcon /> }</span>}
                    helpText={value.showValue ? "Will be encrypted." : ''}
                  />
                </div>
                <span className="text-error-red flex justify-center items-center px-4 border border-error-red rounded hover:cursor-pointer flex-initial ml-4 h-12 mt-8 text-center" onClick={() => handleRemoveEnviromentVar(id)}><TrashIcon /></span>
              </div>
            )}
          )}
          {/* Add button for Environment varible */}
          <a onClick={handleAddEnviromentVar} type="button" href="/#" className={styles.groupInputAddBtn}> <PlusIcon /> Add variable</a>
        </CollapsibleComponent>
        <CollapsibleComponent title="Health" open={false} helpText="Optional">
          <div className="space-y-6">
            <TextInput 
              value={container.healthCheckEndpoint.value} 
              label="Health check path" 
              name="healthCheckEndpoint" 
              onChange={(e) => onChangeHealthCheckEndpoint(e.target.value)} 
              placeholderText="/checkpath"
              infoText="A health check is a HTTP request that will run durning the deployments to ensure a service is healthy."
              isError={!container.branchName.isValid}
              errorMessage={container.branchName.errorMsg}
            />
          </div>
        </CollapsibleComponent>
      </div>
      
      {/* action buttons */}
      <div className={styles.actionBtnWrapper}>
        <Button onClick={onCancel} className={styles.cancelBtn}>
          Cancel
        </Button>
        <Button onClick={handleFormSubmit} type="primary" className={styles.createBtn}>
          Create App
        </Button>
      </div>
    </form>
  )
}

export default CreateContainerForm