import { default  as AgentComponent } from '../components/AIAgentComp/Agent';
import back4app2 from '../back4app2';
import { Agent, AgentStatus, AgentStatusError, AgentsSubscriptionChatMessagesHardLimitError, AuthenticationError, AuthorizationError, BlockedResourceError, ChatMessage, ChatMessageStatus, NetworkError, PaymentPendingError, Subscription } from '@back4app2/sdk';
import { ReactComponent as StatusErrorSVG } from '../assets/images/status-error.svg';
import { BACK4APP_DOT_COM_SITE_URL } from '../settings';
import toast from 'react-hot-toast';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useReducer, useRef } from 'react';
import LoadingMessage from '../components/LoadingMessage';
import ErrorMessage from '../components/ErrorMessage';
import { Message } from '../types/Agent';

interface MyAgentState {
  isLoading: boolean;
  loadingErrorMessage?: string;
  messages?: ChatMessage[];
  paymentErrorMessage?: string;
}

const INITIAL_STATE: MyAgentState = {
  isLoading: true,
  loadingErrorMessage: undefined,
  messages: undefined,
  paymentErrorMessage: undefined
};

enum MyAgentActionType {
  RESET,
  FINISH_LOADING,
  FINISH_SENDING,
  SET_PAYMENT_ERROR_MESSAGE
}

const reset = () => ({
  type: MyAgentActionType.RESET
} as const);

const finishLoading = (errorMessage?: string, messages?: ChatMessage[]) => ({
  type: MyAgentActionType.FINISH_LOADING,
  payload: {
    errorMessage,
    messages,
  }
} as const);

const finishSending = (message: ChatMessage) => ({
  type: MyAgentActionType.FINISH_SENDING,
  payload: {
    message
  }
} as const);

const setPaymentErrorMessage = (errorMessage?: string) => ({
  type: MyAgentActionType.SET_PAYMENT_ERROR_MESSAGE,
  payload: {
    errorMessage
  }
} as const);

type MyAgentAction = ReturnType<typeof reset> | ReturnType<typeof finishLoading> | ReturnType<typeof finishSending> | ReturnType<typeof setPaymentErrorMessage>;

const reducer = (state: MyAgentState = INITIAL_STATE, action: MyAgentAction): MyAgentState => {
  switch (action.type) {
    case MyAgentActionType.RESET:
      return {
        ...INITIAL_STATE
      };
    
    case MyAgentActionType.FINISH_LOADING:
      return {
        ...state,
        isLoading: false,
        loadingErrorMessage: action.payload.errorMessage,
        messages: (action.payload.messages && [...action.payload.messages]) || undefined
      }
    
    case MyAgentActionType.FINISH_SENDING:
      const messages = [...(state.messages || [])];

      if (!messages.find(message => message.id === action.payload.message.id)) {
        messages.push(action.payload.message);
      }

      return {
        ...state,
        messages
      }

    case MyAgentActionType.SET_PAYMENT_ERROR_MESSAGE:
      return {
        ...state,
        paymentErrorMessage: action.payload.errorMessage
      }
  }
};

const MyAgent = forwardRef<{ setInputContent: (content: string) => void }, { agentId: string, agents: Agent[] | undefined, isSidebarOpen: boolean }>(({ agentId, agents, isSidebarOpen }, ref) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const agentRef = useRef<{ setInputContent: (content: string) => void }>(null);

  const { isLoading, loadingErrorMessage, messages, paymentErrorMessage } = state;

  const agent = useMemo(() => agents && agents.find(agent => agent.id === agentId), [agentId, agents]);

  const chatId = useMemo(() => agent && agent.mainChat && agent.mainChat.id, [agent]);

  useImperativeHandle(ref, () => ({
    setInputContent: (content: string) => {
      if (agentRef.current) {
        agentRef.current.setInputContent(content);
      }
    }
  }));

  useEffect(
    () => {
      dispatch(reset());
      
      let subscription: Subscription;

      if (chatId) {
        subscription = back4app2.subscribeToChatMessages(
          chatId,
          (error, snapshot) => {  
            if (error) {
              if (error instanceof NetworkError) {
                console.error('network error', error);
                dispatch(finishLoading('Network error when loading chat messages. Check your internet connection and try again.'));
              } else if (error instanceof AuthenticationError || error instanceof AuthorizationError) {
                window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
              } else {
                console.error('unexpected error loading chat messages', error);
                dispatch(finishLoading('Unexpected error when loading chat messages. Please try again.'));
              }
            } else {
              dispatch(finishLoading(undefined, snapshot));
            }
          }
        );
      }
            
      return () => {
        if (subscription) {
          subscription.unsubscribe();
          dispatch(reset());
        }
      };
    },
    [chatId]
  );

  const translatedMessages = useMemo(
    () => (messages && messages.reduce(
      (messages, message): Message[] => {
        return [
          ...messages,
          {
            id: `${message.id}-user`,
            role: 'user',
            content: message.question
          },
          {
            id: `${message.id}-assistant`,
            role: 'assistant',
            content: message.aiResponse || '',
            isTyping: !message.respondedAt,
            hasFailed: message.status === ChatMessageStatus.FAILED
          }
        ];
      },
      [] as Message[]
    )) || undefined,
    [messages]
  )

  const onSend = async (content: string): Promise<boolean> => {
    let message;

    try {
      message = await back4app2.askQuestionToAgent(agentId, content);
    } catch (e) {
      let errorMessage;

      if (e instanceof NetworkError) {
        console.error('network error', e);
        errorMessage = 'Network error when asking question to agent. Check your internet connection.';
      } else if (e instanceof AuthenticationError || e instanceof AuthorizationError) {
        window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
        return false;
      } else if (e instanceof AgentStatusError) {
        if (agent && agent.status === AgentStatus.FAILED) {
          errorMessage = 'Sorry, this agent has failed and can not respond to messages. Please create a new agent or contact our supporting team.';
        } else {
          errorMessage = 'This agent is still initializing and can not respond to messages. Please try again later.';
        }
      } else if (e instanceof BlockedResourceError) {
        errorMessage = 'This agent is blocked and can not respond to messages. If you believe that it is an error, please contact our supporting team.';
      } else if (e instanceof PaymentPendingError) {
        errorMessage = 'Please complete the payment process in order to ask questions to the agent.';
        dispatch(setPaymentErrorMessage(errorMessage))
      } else if (e instanceof AgentsSubscriptionChatMessagesHardLimitError) {
        errorMessage = 'Please subscribe to a paying plan in order to continue asking questions to the agent.';
        dispatch(setPaymentErrorMessage(errorMessage))
      } else {
        console.error('unexpected error asking question to agent', e);
        errorMessage = 'Unexpected error when asking question to agent. Please try again later.';
      }

      toast.error(errorMessage as string, {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-lg text-sm",
        icon: <StatusErrorSVG width="24px" height="24px" className="animate-bounce-in" />,
        duration: 6000
      });

      return false;
    }

    dispatch(finishSending(message));
    
    return true;
  }

  if (!chatId) {
    return (
      <LoadingMessage message="Loading agent..." />
    );
  }

  if (isLoading) {
    return (
      <LoadingMessage message="Loading messages..." />
    );
  }

  if (loadingErrorMessage) {
    return (
      <ErrorMessage message={loadingErrorMessage} />
    );
  }

  return (
    <AgentComponent onSend={onSend} messages={translatedMessages} isSidebarOpen={isSidebarOpen} paymentErrorMessage={paymentErrorMessage} agentId={agentId} ref={agentRef} />
  );
});

export default MyAgent;
