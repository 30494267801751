import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Message } from "../../types/Agent";
import AgentInput from "./AgentInput";
import AgentMessage from "./AgentMessage";
import { ReactComponent as ErrorIconSVG } from '../../assets/images/error-icon.svg';
import Button from "../Button";
import { BACK4APP_DOT_COM_SITE_URL } from "../../settings";

const Agent = forwardRef<{ setInputContent: (content: string) => void }, { agentId: string, messages?: Message[], isSidebarOpen: boolean, paymentErrorMessage?: string, onSend: (content: string) => Promise<boolean> }>(({ agentId, messages, onSend, isSidebarOpen, paymentErrorMessage }, ref) => {
  const [autoScroll, setAutoScroll] = useState(true);
  const boxRef = useRef<HTMLDivElement>(null);
  const agentInputRef = useRef<{ setContent: (content: string) => void }>(null);

  useImperativeHandle(ref, () => ({
    setInputContent: (content: string) => {
      if (agentInputRef.current) {
        agentInputRef.current.setContent(content);
      }
    }
  }));

  useEffect(
    () => {
      if (messages && autoScroll && boxRef.current) {
        boxRef.current.scrollTop = boxRef.current.scrollHeight;
      }
    },
    [
      messages,
      autoScroll
    ]
  );

  const onScroll = () => {
    if (boxRef.current && (boxRef.current.scrollTop + boxRef.current.clientHeight) >= 0.99 * boxRef.current.scrollHeight && !autoScroll) {
      setAutoScroll(true);
    } else if (boxRef.current && (boxRef.current.scrollTop + boxRef.current.clientHeight) < 0.99 * boxRef.current.scrollHeight && autoScroll) {
      setAutoScroll(false);
    }
  };

  return (
    <div className={`relative h-full w-full transition-all duration-500 ${isSidebarOpen ? 'max-w-[calc(100vw-19.75rem)]' : 'max-w-[calc(100vw-1rem)]'}`}>
      <div className="h-full w-full overflow-y-scroll" ref={boxRef} onScroll={onScroll}>
        <div className="w-full px-[7.5rem] pb-[15rem] py-5">
          <AgentMessage
            message={{
              id: 'welcome',
              role: 'assistant',
              content: `Hello! I'm a DevOps AI Execution Agent, integrated with Back4app's Backend and Container platforms, and equipped with a developer environment and GitHub integration. Here's some prompts examples to start with:

"Deploy my web app from GitHub"
"Set up a new developer environment with cURL, git and nodeJS installed"
"Clone my GitHub repository in a new developer environment"
"Push the changes you've made on my project to GitHub"
"Create a new App for my SaaS platform"
"Create a new cloud function to integrate myApp to Stripe for checkout and payments"

For more details on usage, see the [Back4App Agent documentation](https://www.back4app.com/docs-agent).`
            }}
          />
          {messages?.map(message => <AgentMessage key={message.id} message={message} />)}
          {paymentErrorMessage && (
            <div className="grow flex flex-col items-center justify-center mt-10">
            <ErrorIconSVG />
            <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey mb-8">
              {paymentErrorMessage}
            </div>
            <Button type='submit'><a role="button" target="_blank" rel='noreferrer noopener' href={`${BACK4APP_DOT_COM_SITE_URL}/pricing/agent?appId=${agentId}&type=agent`}>Pay Now</a></Button>
          </div>
          )}
        </div>
      </div>
      <AgentInput onSend={onSend} ref={agentInputRef}  />
    </div>
  )
});

export default Agent;
