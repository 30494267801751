import { GitHubRepository, ProjectType } from "@back4app2/sdk/lib/Back4app2";
import { ReactComponent as DockerLogoSVG } from "../../assets/images/docker-logo.svg";
import { ReactComponent as AttentionIconSVG } from "../../assets/images/circle-exclaimation-icon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/error-icon.svg";
import { ReactComponent as GhostIcon } from "../../assets/images/ghost-icon.svg";
import { ReactComponent as GithubIcon } from "../../assets/images/github-logo.svg";
import { ReactComponent as B4AIconSVG } from "../../assets/images/b4a-sqaure-glow-logo.svg";

import styles from './IntegrateGithub.module.css';
import Button from "../Button";
import { timeFromNow } from "../../utils/dates";
import MessageContainer from "./MessageContainer/MessageContainer";
import Skeleton from "../Skeleton";

interface IntegrateGithubProps {
  githubRepos: GitHubRepository[] | undefined,
  isImporting: Boolean,
  errorMsg: string | undefined,
  onImport: (repository: GitHubRepository) => void,
  filterText: string | undefined;
  isLoadingRepository: boolean
}

const IntegrateGithub = ({ githubRepos, isImporting, errorMsg, onImport, filterText, isLoadingRepository }: IntegrateGithubProps) => {

  if (isImporting) {
    return <>
      <div className="border-b border-b-regal-blue px-7 py-20">
        <div className="flex justify-center items-center overflow-visible h-6 mb-4 z-[-1]">
          <GithubIcon width="24px" height="24px" />
          <div className="flex space-x-2 ml-5">
            <span className="w-1 h-1 rounded bg-[#D9D9D9] animate-dot-flashing"></span>
            <span className="w-1 h-1 rounded bg-[#D9D9D9] animate-[dot-flashing_1s_infinite_0.5s_alternate] "></span>
            <span className="w-1 h-1 rounded bg-[#D9D9D9] animate-[dot-flashing_1s_infinite_1s_alternate] "></span>
          </div>
          <B4AIconSVG width="76px" height="76px" />
        </div>
        <div className="font-sora font-semibold text-lg leading-140 text-center">Loading repositories</div>
      </div>
      <MessageContainer 
        msgTitle=""
        msgDesc="Not seeing the repositories you expected here?"
        showButton={true}
        buttonText="Edit GitHub Permissions"
        buttonAction="EDIT"
        extra={<div className="flex gap-2 items-center text-sm leading-140 mt-4"><AttentionIconSVG className="text-alert-yellow" width="15px" />A Dockerfile is mandatory for deploying your applications; please ensure it's included in your repo.</div>}
      />
    </>
  }

  if (isLoadingRepository) {
    return <>
      {Array(4).fill(0).map((_value, index) => <GithubRepositoryItem key={index} loading={isLoadingRepository} onImport={onImport} />)}
      <MessageContainer 
        msgTitle=""
        msgDesc="Not seeing the repositories you expected here?"
        showButton={true}
        buttonText="Edit GitHub Permissions"
        buttonAction="EDIT"
        extra={<div className="flex gap-2 items-center text-sm leading-140 mt-4"><AttentionIconSVG className="text-alert-yellow" width="15px" />A Dockerfile is mandatory for deploying your applications; please ensure it's included in your repo.</div>}
      />
    </>
  }

  if (errorMsg) {
    return <MessageContainer 
      msgTitle={errorMsg} 
      primaryIcon={<ErrorIcon />} 
      msgDesc={"Please try again."} 
      showButton={true}
      buttonText="Import GitHub Repo"
      buttonAction="IMPORT"
    />
  }

  if (!githubRepos || githubRepos.length === 0) {
    return <MessageContainer 
      msgTitle={"Nothing here, yet!"} 
      primaryIcon={<GhostIcon />}
      showButton={true}
      buttonText="Import GitHub Repo"
      buttonAction="IMPORT"
      extra={<div className="flex gap-2 items-center text-sm leading-140 mt-4"><AttentionIconSVG className="text-alert-yellow" width="15px" />A Dockerfile is mandatory for deploying your applications; please ensure it's included in your repo.</div>}
    />
  }

  const filteredRepositoryList = githubRepos.filter(repository => {
    if (filterText) {
      const regex = new RegExp(`${filterText}`, 'ig');
      return repository.fullName.match(regex);
    }
    return repository;
  })

  return (
    <>
      {filteredRepositoryList.map(repo => {
        return(
          <GithubRepositoryItem key={repo.id}
            loading={isLoadingRepository} 
            repositoryData={{...repo}}
            onImport={onImport} 
          />
        )}
      )}
      <MessageContainer 
        msgTitle=""
        msgDesc="Not seeing the repositories you expected here?"
        showButton={true}
        buttonText="Edit GitHub Permissions"
        buttonAction="EDIT"
        extra={<div className="flex gap-2 items-center text-sm leading-140 mt-4"><AttentionIconSVG className="text-alert-yellow" width="15px" />A Dockerfile is mandatory for deploying your applications; please ensure it's included in your repo.</div>}
      />
    </>
  )
}

export default IntegrateGithub

const GithubRepositoryItem = (props: { loading: boolean, repositoryData?: GitHubRepository, onImport: (repository: GitHubRepository) => void}) => {
  let { loading, repositoryData, onImport } = props;

  return <div className="flex justify-between items-center py-4 px-[1.875rem] bg-dark-blue border-b border-b-regal-blue">
    <div className="flex items-center basis-2/3 max-w-[66%]">
      <GithubIcon width="20px" height="20px" className={`flex-none ${loading ? 'text-white/[0.48]' : 'text-white'}`} />
      <span className="text-lg leading-140 text-light-blue ml-2 truncate">{loading ? <Skeleton className="h-4 w-80" /> : <a href={`https://www.github.com/${repositoryData?.fullName}`} target="_blank" rel="noopener noreferrer" className="hover:underline hover:underline-offset-2">{repositoryData?.fullName}</a>}</span>
      {!loading && repositoryData?.projectType === ProjectType.DOCKER &&
        <DockerLogoSVG width="20px" className="flex-none ml-4 text-old-blue" />
      }
    </div>
    <div className="flex items-center justify-end basis-1/3 space-x-8">
      {loading ? <Skeleton className="h-4 w-20" /> : <span className={`text-sm leading-140 mr-8`}>{repositoryData?.pushedAt && timeFromNow(repositoryData.pushedAt)}</span>}
      {loading ? <Skeleton className="h-4 w-20" /> : <Button onClick={() => { repositoryData && onImport(repositoryData)}} type="primary" className={styles.importBtn}>Select</Button>}
    </div>
  </div>
}