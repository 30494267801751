import { useEffect, useReducer } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { App, AppStatus, AuthenticationError, AuthorizationError, DeploymentMemoryExceededError, DeploymentLog, DockerfileNotFoundError, NetworkError, Subscription, User, DeploymentStatus, KanikoImageBuildError, ContainerLog, ContainerStats, GitHubResourceNotFoundError, GitRepositoryEmptyError } from '@back4app2/sdk';
import MyAppDeploymentStatus from '../components/MyAppDeploymentStatus';
import { ReactComponent as GhostIconSVG } from "../assets/images/ghost-icon.svg";
import { ReactComponent as BranchIconSmallSVG } from '../assets/images/branch-icon-small.svg';
import Timer from '../components/Timer';
import { timeFromNow } from '../utils/dates';
import buildCommitAuthorsMessage from '../utils/buildCommitAuthorsMessage';
import back4app2 from '../back4app2';
import { BACK4APP_DOT_COM_SITE_URL } from '../settings';
import LogBox from '../components/LogBox';
import Guides from '../components/Guides';
import { AmplitudeEvent, trackEvent } from '../utils/amplitude';
import CancelBuildButton from '../components/CancelBuildButton';
import DockerfileErrorMessage from '../components/CustomErrorMessageUI/DockerfileErrorMessage';
import VerifyEmailErrorMessage from '../components/CustomErrorMessageUI/VerifyEmailErrorMessage';
import OutOfMemoryError from '../components/CustomErrorMessageUI/OutOfMemoryError';
import PaymentPendingErrorMessage from '../components/CustomErrorMessageUI/PaymentPendingErrorMessage';
import SleepingContainerMessage from '../components/CustomErrorMessageUI/SleepingContainerMessage';
import ImageBuildError from '../components/CustomErrorMessageUI/ImageBuildError';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorMessage from '../components/ErrorMessage';
import CPUGraph from '../components/Charts/CPUGraph';
import RAMGraph from '../components/Charts/RAMGraph';
import PlanWidget from '../components/PlanWidget';
import { getContainerStatsGraphData } from '../utils/metrics';
import ContainerLogBox from '../components/ContainerLogBox';
import AIAssisant from '../components/AIAssisant';
import ResourceNotAccessibleError from '../components/CustomErrorMessageUI/ResourceNotAccessibleError';

interface MyAppOverviewState {
  isLoadingDeploymentLogs: boolean;
  deploymentLogsLoadingErrorMessage?: string;
  deploymentLogs?: DeploymentLog[];
  isLoadingStats: boolean;
  loadingStatsErrorMessage?: string;
  colors?: { [containerId: string]: string };
  cpuGraphProps?: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string }};
  ramGraphProps?: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string }, max: number};
  isLoadingContainerLogs: boolean;
  containerLogsLoadingErrorMessage?: string;
  containerLogs?: ContainerLog[];
}

const INITIAL_STATE: MyAppOverviewState = {
  isLoadingDeploymentLogs: true,
  isLoadingStats: true,
  isLoadingContainerLogs: true
};

enum MyAppOverviewActionType {
  RESET,
  FINISH_LOADING_DEPLOYMENT_LOGS,
  FINISH_LOADING_STATS,
  FINISH_LOADING_CONTAINER_LOGS,
  RESET_DEPLOYMENT_LOGS,
  RESET_CONTAINER_STATS,
  RESET_CONTAINER_LOGS,
}

const reset = () => ({
  type: MyAppOverviewActionType.RESET
} as const);

const resetDeploymentLogs = () => ({
  type: MyAppOverviewActionType.RESET_DEPLOYMENT_LOGS
} as const);
const resetContainerLogs = () => ({
  type: MyAppOverviewActionType.RESET_CONTAINER_LOGS
} as const);
const resetContainerStats = () => ({
  type: MyAppOverviewActionType.RESET_CONTAINER_STATS
} as const);

const finishLoadingDeploymentLogs = (errorMessage?: string, deploymentLogs?: DeploymentLog[]) => ({
  type: MyAppOverviewActionType.FINISH_LOADING_DEPLOYMENT_LOGS,
  payload: {
    errorMessage,
    deploymentLogs
  }
} as const);

const finishLoadingStats = (errorMessage?: string, stats?: ContainerStats[]) => ({
  type: MyAppOverviewActionType.FINISH_LOADING_STATS,
  payload: {
    errorMessage,
    stats
  }
} as const);

const finishLoadingContainerLogs = (errorMessage?: string, logs?: ContainerLog[]) => ({
  type: MyAppOverviewActionType.FINISH_LOADING_CONTAINER_LOGS,
  payload: {
    errorMessage,
    logs
  }
} as const);

const containerStatsTimeWindow = 1 * 60 * 60 * 1000;

type MyAppOverviewAction = ReturnType<typeof reset> | ReturnType<typeof finishLoadingDeploymentLogs> | ReturnType<typeof finishLoadingContainerLogs>  | ReturnType<typeof finishLoadingStats>  | ReturnType<typeof resetDeploymentLogs>  | ReturnType<typeof resetContainerLogs>  | ReturnType<typeof resetContainerStats>;

const reducer = (state: MyAppOverviewState = INITIAL_STATE, action: MyAppOverviewAction): MyAppOverviewState => {
  switch (action.type) {
    case MyAppOverviewActionType.RESET: 
      return INITIAL_STATE;

    case MyAppOverviewActionType.RESET_DEPLOYMENT_LOGS:
      return {
        ...state,
        isLoadingDeploymentLogs: true,
        deploymentLogsLoadingErrorMessage: undefined,
        deploymentLogs: undefined

      }
    case MyAppOverviewActionType.RESET_CONTAINER_LOGS:
      return {
        ...state,
        isLoadingContainerLogs: true,
        containerLogsLoadingErrorMessage: undefined,
        containerLogs: undefined
      }

    case MyAppOverviewActionType.RESET_CONTAINER_STATS:
      return {
        ...state,
        isLoadingStats: true,
        loadingStatsErrorMessage: undefined,
        colors: undefined,
        cpuGraphProps: undefined,
        ramGraphProps: undefined
      }

    case MyAppOverviewActionType.FINISH_LOADING_DEPLOYMENT_LOGS:
      return {
        ...state,
        isLoadingDeploymentLogs: false,
        deploymentLogsLoadingErrorMessage: action.payload.errorMessage,
        deploymentLogs: action.payload.deploymentLogs
      }
    case MyAppOverviewActionType.FINISH_LOADING_STATS: {
      const { colors,
        cpuGraphProps,
        ramGraphProps,
      } = getContainerStatsGraphData(action.payload.stats || [], state.colors)
      
      return {
        ...state,
        isLoadingStats: false,
        loadingStatsErrorMessage: action.payload.errorMessage,
        colors,
        cpuGraphProps,
        ramGraphProps
      };
    }
    case MyAppOverviewActionType.FINISH_LOADING_CONTAINER_LOGS:
      return {
        ...state,
        isLoadingContainerLogs: false,
        containerLogsLoadingErrorMessage: action.payload.errorMessage,
        containerLogs: action.payload.logs
      };
  }
};

const MyAppOverview = () => {
  const { app, appLoadedAt, user, freePlanUsageHours } = useOutletContext<{ app: App, appLoadedAt: Date, user: User, freePlanUsageHours: number }>();
  const mainServiceEnvironment = app && app.mainService && app.mainService.mainServiceEnvironment;
  const mainServiceEnvironmentId = mainServiceEnvironment && mainServiceEnvironment.id;
  const lastDeployment = app && app.mainService && app.mainService.mainServiceEnvironment && app.mainService.mainServiceEnvironment.lastDeployment;
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const navigate = useNavigate();
  const { deploymentLogsLoadingErrorMessage, deploymentLogs, isLoadingDeploymentLogs, containerLogsLoadingErrorMessage, containerLogs, isLoadingContainerLogs, isLoadingStats, loadingStatsErrorMessage, cpuGraphProps, ramGraphProps } = state;

  useEffect(
    () => {
      let subscription: Subscription;

      if (lastDeployment?.id) {
        subscription = back4app2.subscribeToDeploymentLogs(
          lastDeployment.id,
          (error, snapshot) => {
            if (error) {
              if (error instanceof NetworkError) {
                console.error('network error', error);
                dispatch(finishLoadingDeploymentLogs('Network error when loading last deployment logs. Check your internet connection and try again.'));
              } else if (error instanceof AuthenticationError || error instanceof AuthorizationError) {
                window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
              } else {
                console.error('unexpected error loading last deployment logs', error);
                dispatch(finishLoadingDeploymentLogs('Unexpected error when loading last deployment logs. Please try again.'));
              }
            } else {
              dispatch(finishLoadingDeploymentLogs(undefined, snapshot));
            }
          }
        );
      }

      return () => {
        if (subscription) {
          subscription.unsubscribe();
          dispatch(resetDeploymentLogs());
        }
      };
    },
    [
      lastDeployment?.id
    ]
  );

  useEffect(() => {
    let statsSubscription: Subscription;
    if (mainServiceEnvironmentId) {
      statsSubscription = back4app2.subscribeToContainersStats(
        mainServiceEnvironmentId,
        containerStatsTimeWindow,
        (error, snapshot) => {
          if (error) {
            if (error instanceof NetworkError) {
              console.error('network error', error);
              dispatch(finishLoadingStats('Network error when loading app stats. Check your internet connection and try again.'));
            } else if (error instanceof AuthenticationError || error instanceof AuthorizationError) {
              window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
            } else {
              console.error('unexpected error loading app stats', error);
              dispatch(finishLoadingStats('Unexpected error when loading app stats. Please try again.'));
            }
          } else {
            dispatch(finishLoadingStats(undefined, snapshot));
          }
        }
      )
    }
  
    return () => {
      if (statsSubscription) {
        statsSubscription.unsubscribe();          
        dispatch(resetContainerStats());
      }
    }
  }, [mainServiceEnvironmentId]);

  useEffect(
    () => {
      let subscription: Subscription;

      if (mainServiceEnvironmentId) {
        subscription = back4app2.subscribeToContainersLogs(
          mainServiceEnvironmentId,
          (error, snapshot) => {
            if (error) {
              if (error instanceof NetworkError) {
                console.error('network error', error);
                dispatch(finishLoadingContainerLogs('Network error when loading app logs. Check your internet connection and try again.'));
              } else if (error instanceof AuthenticationError || error instanceof AuthorizationError) {
                window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
              } else {
                console.error('unexpected error loading app logs', error);
                dispatch(finishLoadingContainerLogs('Unexpected error when loading app logs. Please try again.'));
              }
            } else {
              dispatch(finishLoadingContainerLogs(undefined, snapshot));
            }
          }
        );
      }

      return () => {
        if (subscription) {
          subscription.unsubscribe();
          dispatch(resetContainerLogs());
        }
      };
    },
    [
      mainServiceEnvironmentId
    ]
  );

  useEffect(() => {
    trackEvent(AmplitudeEvent.AT_APP_OVERVIEW_PAGE);
    return () => {
      dispatch(reset())
    }
  }, []);

  return (<>
    <div className="flex w-full gap-4 flex-wrap mb-4 justify-between 2xl:justify-start">
      <div className="flex-none min-w-[312px]"><PlanWidget plan={app.mainService?.mainServiceEnvironment?.plan} appId={app.id} freePlanUsageHours={freePlanUsageHours} /></div>
      <div className="flex-grow grid laptop:grid-cols-2 grid-rows-2 laptop:grid-rows-1 gap-4">
        <div className="bg-white/[0.06] min-w-[312px] h-[143px] rounded py-2 flex flex-col gap-1">
          <div className="font-sora text-lg leading-[140%] text-white px-[1.25rem]">CPU</div>
          {isLoadingStats && mainServiceEnvironmentId ? (<>
            <div className="flex flex-col justify-center items-center">
              <LoadingSpinner />
              <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
                Loading
              </div>
            </div>
          </>) : (<>
            {loadingStatsErrorMessage ? (<>
              <ErrorMessage message={loadingStatsErrorMessage} />
            </>) : (<>
              {cpuGraphProps && cpuGraphProps.data.length > 0 ? (<>
                <div className="h-full w-100">
                  <CPUGraph
                    data={cpuGraphProps.data}
                    colors={cpuGraphProps.colors}
                    hideLegend={true}
                    axisLegendColor="#F9F9F9"
                    yAxisTicksCount={3}
                    timeWindow={containerStatsTimeWindow}
                  />
                </div>
              </>) : (<>
                <div className="flex flex-col items-center">
                  <GhostIconSVG />
                  <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
                    Nothing here, yet!
                  </div>
                </div>
              </>)}
            </>)}
          </>)}
        </div>
        <div className="bg-white/[0.06] min-w-[312px] h-[143px] rounded py-2 flex flex-col gap-1">
          <div className="font-sora text-lg leading-[140%] text-white px-[1.25rem]">RAM</div>
          {isLoadingStats && mainServiceEnvironmentId ? (<>
            <div className="flex flex-col justify-center items-center">
              <LoadingSpinner />
              <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
                Loading
              </div>
            </div>
          </>) : (<>
            {loadingStatsErrorMessage ? (<>
              <ErrorMessage message={loadingStatsErrorMessage} />
            </>) : (<>
              {ramGraphProps && ramGraphProps.data.length > 0 ? (<>
                <div className="h-full w-100">
                  <RAMGraph
                    data={ramGraphProps.data}
                    colors={ramGraphProps.colors}
                    max={ramGraphProps.max}
                    hideLegend={true}
                    axisLegendColor="#F9F9F9"
                    yAxisTicksCount={3}
                    timeWindow={containerStatsTimeWindow}
                  />
                </div>
              </>) : (<>
                <div className="flex flex-col items-center">
                  <GhostIconSVG />
                  <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
                    Nothing here, yet!
                  </div>
                </div>
              </>)}
            </>)}
          </>)}
        </div>
      </div>
    </div>
    {app.status === AppStatus.PENDING_VERIFICATION ? <VerifyEmailErrorMessage userEmail={user.username} /> : (
      <>
        {app.mainService?.mainServiceEnvironment?.isPendingPayment || (!app.mainService?.mainServiceEnvironment?.isFreePlanElegible && app.mainService?.mainServiceEnvironment?.plan.name.includes('Free')) ? (
          <PaymentPendingErrorMessage appId={app.id} mainServiceEnvironment={app.mainService.mainServiceEnvironment} />
        ) : (
          <>
            {lastDeployment?.error ? 
              lastDeployment?.error instanceof DockerfileNotFoundError ? <DockerfileErrorMessage /> 
              : lastDeployment?.error instanceof KanikoImageBuildError ? <ImageBuildError errorMessage={lastDeployment.error.message} /> 
              : lastDeployment?.error instanceof DeploymentMemoryExceededError ? <OutOfMemoryError appId={app.id} /> 
              : lastDeployment?.error instanceof GitHubResourceNotFoundError ? <ResourceNotAccessibleError />
              : lastDeployment?.error instanceof GitRepositoryEmptyError ? null
              : <AIAssisant deployment={lastDeployment} /> : null}
            {lastDeployment?.status === DeploymentStatus.SLEEPING ? <SleepingContainerMessage appId={app.id} /> : null}
            <div className="bg-white/[0.06] rounded p-4 mb-4">
              <div className="flex justify-between items-center">
                <h1 className="font-sora text-lg leading-[140%]">Last Deployment</h1>    
                <button onClick={() => navigate(`/apps/${app.id}/deployments`)} className='border border-cta-green rounded outline-none text-cta-green py-2 px-2.5 text-xs font-inter font-medium'>All Deployments</button>
              </div>
              {lastDeployment ? (<>
                <div className="mt-4 px-4 py-2">
                  <div className="py-1 flex items-center gap-1.5">
                    <div className="flex items-center gap-2 w-1/2">
                      <div className="text-sm leading-[140%] truncate">
                        {lastDeployment.id}
                      </div>
                      <div className="flex items-center gap-1">
                        <BranchIconSmallSVG />
                        <div className="text-light-blue text-xs leading-[140%] truncate">
                          {lastDeployment.branchName}
                        </div>
                      </div>
                    </div>
                    <div className="grow flex justify-end">
                      <MyAppDeploymentStatus deployment={lastDeployment} />
                    </div>
                    <div>
                      <Timer
                        realElapsedMS={(lastDeployment.deployTask && (lastDeployment.deployTask.elapsedTimeMS + (lastDeployment.deployTask && lastDeployment.deployTask.finishedAt ? 0 : new Date().getTime() - appLoadedAt.getTime()))) || 0}
                        hasFinished={(lastDeployment.deployTask && lastDeployment.deployTask.finishedAt instanceof Date) || false}
                      />
                    </div>
                    <CancelBuildButton deployment={lastDeployment} />
                  </div>
                  {(lastDeployment.commitMessage || lastDeployment.committedAt || lastDeployment.commitAuthorLogin || lastDeployment.commitCommitterLogin) && (<>
                    <div className="flex gap-4 justify-between">
                      <div className="grow text-light-blue text-xs leading-[140%] truncate max-w-[15rem]">
                        {lastDeployment.commitMessage || ''}
                      </div>
                      <div className="max-w-[50%] text-light-blue text-xs leading-[140%] text-right">
                        {(lastDeployment.committedAt && timeFromNow(lastDeployment.committedAt)) || ''}
                        {((lastDeployment.commitAuthorLogin || lastDeployment.commitCommitterLogin) && ` ${buildCommitAuthorsMessage(lastDeployment.commitAuthorLogin, lastDeployment.commitCommitterLogin)}`) || ''}
                      </div>
                    </div>
                  </>)}
                </div>      
                <LogBox errorMessage={deploymentLogsLoadingErrorMessage} logs={deploymentLogs} isLoading={isLoadingDeploymentLogs} canMaximize />
              </>) : (<>
                <div className="mt-10 flex flex-col items-center">
                  <GhostIconSVG />
                  <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
                    Nothing here, yet!
                  </div>
                </div>
              </>)}
            </div>
            <div className="bg-white/[0.06] rounded p-4 mb-4">
              <div className="flex justify-between items-center">
                <h1 className="font-sora text-lg leading-[140%]">Logs</h1>    
                <button onClick={() => navigate(`/apps/${app.id}/logs`)} className='border border-cta-green rounded outline-none text-cta-green py-2 px-2.5 text-xs font-inter font-medium'>Go to Logs</button>
              </div>
              {mainServiceEnvironmentId ? (
                <ContainerLogBox errorMessage={containerLogsLoadingErrorMessage} logs={containerLogs} isLoading={isLoadingContainerLogs} canMaximize />
              ) : (
                <div className="mt-10 flex flex-col items-center">
                  <GhostIconSVG />
                  <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
                    Nothing here, yet!
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    )}
    <div className="mt-14">
      <Guides />
    </div>
  </>);
};

export default MyAppOverview;
