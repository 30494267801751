"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NetworkError_1 = require("./NetworkError");
var InternalServerError_1 = require("./InternalServerError");
var Back4app2Error_1 = require("./Back4app2Error");
var AuthorizationError_1 = require("./AuthorizationError");
var NotFoundError_1 = require("./NotFoundError");
var GitHubCodeError_1 = require("./GitHubCodeError");
var GitHubInstallationIdError_1 = require("./GitHubInstallationIdError");
var UnexpectedError_1 = require("./UnexpectedError");
var AuthenticationError_1 = require("./AuthenticationError");
var DuplicatedError_1 = require("./DuplicatedError");
var GitHubInstallationError_1 = require("./GitHubInstallationError");
var TimeoutError_1 = require("./TimeoutError");
var DockerfileNotFoundError_1 = require("./DockerfileNotFoundError");
var GitHubResourceNotFoundError_1 = require("./GitHubResourceNotFoundError");
var BlockedResourceError_1 = require("./BlockedResourceError");
var UserVerificationPendingError_1 = require("./UserVerificationPendingError");
var DeploymentMemoryExceededError_1 = require("./DeploymentMemoryExceededError");
var DeploymentStatusError_1 = require("./DeploymentStatusError");
var AppStatusError_1 = require("./AppStatusError");
var PaymentPendingError_1 = require("./PaymentPendingError");
var InvalidInputError_1 = require("./InvalidInputError");
var DisposableEmailError_1 = require("./DisposableEmailError");
var KanikoImageBuildError_1 = require("./KanikoImageBuildError");
var DNSVerificationError_1 = require("./DNSVerificationError");
var GitHubReadWritePermissionError_1 = require("./GitHubReadWritePermissionError");
var GitRepositoryEmptyError_1 = require("./GitRepositoryEmptyError");
var BadDevelopmentEnvironmentError_1 = require("./BadDevelopmentEnvironmentError");
var CommandNotExecutingError_1 = require("./CommandNotExecutingError");
var AgentsSubscriptionAmountHardLimitError_1 = require("./AgentsSubscriptionAmountHardLimitError");
var AgentsSubscriptionChatMessagesHardLimitError_1 = require("./AgentsSubscriptionChatMessagesHardLimitError");
var ChatMessageStatusError_1 = require("./ChatMessageStatusError");
var AgentStatusError_1 = require("./AgentStatusError");
var CaptchaError_1 = require("./CaptchaError");
var CreateParseAppError_1 = require("./CreateParseAppError");
function parseBack4app2Error(error) {
    if (error instanceof Back4app2Error_1.default) {
        return error;
    }
    if (typeof error === 'string') {
        try {
            error = JSON.parse(error);
        }
        catch (e) {
            console.error('could not parse error', error, e);
        }
    }
    if (!error ||
        typeof error !== 'object' ||
        !error['code'] ||
        !error['message']) {
        return null;
    }
    switch (error['code']) {
        case NetworkError_1.default.CODE:
            if (error['message'] === NetworkError_1.default.MESSAGE) {
                return new NetworkError_1.default();
            }
            else {
                return null;
            }
        case InternalServerError_1.default.CODE:
            if (error['message'] === InternalServerError_1.default.MESSAGE) {
                return new InternalServerError_1.default();
            }
            else {
                return null;
            }
        case AuthenticationError_1.default.CODE:
            return new AuthenticationError_1.default(error['message']);
        case AuthorizationError_1.default.CODE:
            return new AuthorizationError_1.default(error['message']);
        case CaptchaError_1.default.CODE:
            return new CaptchaError_1.default(error['message']);
        case NotFoundError_1.default.CODE:
            return new NotFoundError_1.default(error['message']);
        case DuplicatedError_1.default.CODE:
            return new DuplicatedError_1.default(error['message']);
        case DisposableEmailError_1.default.CODE:
            if (error['message'] === DisposableEmailError_1.default.MESSAGE) {
                return new DisposableEmailError_1.default();
            }
            else {
                return null;
            }
        case UserVerificationPendingError_1.default.CODE:
            return new UserVerificationPendingError_1.default(error['message']);
        case GitHubCodeError_1.default.CODE:
            if (error['message'] === GitHubCodeError_1.default.MESSAGE) {
                return new GitHubCodeError_1.default();
            }
            else {
                return null;
            }
        case GitHubReadWritePermissionError_1.default.CODE:
            return new GitHubReadWritePermissionError_1.default(error['message']);
        case GitRepositoryEmptyError_1.default.CODE:
            return new GitRepositoryEmptyError_1.default(error['message']);
        case DockerfileNotFoundError_1.default.CODE:
            return new DockerfileNotFoundError_1.default(error['message']);
        case DeploymentMemoryExceededError_1.default.CODE:
            return new DeploymentMemoryExceededError_1.default(error['message']);
        case DeploymentStatusError_1.default.CODE:
            return new DeploymentStatusError_1.default(error['message']);
        case AppStatusError_1.default.CODE:
            return new AppStatusError_1.default(error['message']);
        case CreateParseAppError_1.default.CODE:
            return new CreateParseAppError_1.default(error['message']);
        case GitHubInstallationIdError_1.default.CODE:
            if (error['message'] === GitHubInstallationIdError_1.default.MESSAGE) {
                return new GitHubInstallationIdError_1.default();
            }
            else {
                return null;
            }
        case GitHubInstallationError_1.default.CODE:
            return new GitHubInstallationError_1.default(error['message']);
        case GitHubResourceNotFoundError_1.default.CODE:
            return new GitHubResourceNotFoundError_1.default(error['message']);
        case UnexpectedError_1.default.CODE:
            if (error['message'] === UnexpectedError_1.default.MESSAGE) {
                return new UnexpectedError_1.default();
            }
            else {
                return null;
            }
        case TimeoutError_1.default.CODE:
            return new TimeoutError_1.default(error['message']);
        case BlockedResourceError_1.default.CODE:
            return new BlockedResourceError_1.default(error['message']);
        case PaymentPendingError_1.default.CODE:
            return new PaymentPendingError_1.default(error['message']);
        case InvalidInputError_1.default.CODE:
            return new InvalidInputError_1.default(error['message']);
        case KanikoImageBuildError_1.default.CODE:
            return new KanikoImageBuildError_1.default(error['message']);
        case 'NOT_CONTAINERS_BETA_USER':
            return new Back4app2Error_1.default(error['message'], error['code']);
        case DNSVerificationError_1.default.CODE:
            return new DNSVerificationError_1.default(error['message']);
        case AgentsSubscriptionAmountHardLimitError_1.default.CODE:
            return new AgentsSubscriptionAmountHardLimitError_1.default(error['message']);
        case AgentsSubscriptionChatMessagesHardLimitError_1.default.CODE:
            return new AgentsSubscriptionChatMessagesHardLimitError_1.default(error['message']);
        case ChatMessageStatusError_1.default.CODE:
            return new ChatMessageStatusError_1.default(error['message']);
        case AgentStatusError_1.default.CODE:
            return new AgentStatusError_1.default(error['message']);
        case BadDevelopmentEnvironmentError_1.default.CODE:
            return new BadDevelopmentEnvironmentError_1.default(error['message']);
        case CommandNotExecutingError_1.default.CODE:
            return new CommandNotExecutingError_1.default(error['message']);
        default:
            return null;
    }
}
exports.default = parseBack4app2Error;
