import { ILink, Navbar, IRouter } from '@back4app2/react-components';
import { AppPlan, AppPlanStatus, AuthenticationError, AuthorizationError, Back4app2Error, NetworkError, NotFoundError, User } from '@back4app2/sdk';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import back4app2, { useBack4app2ConnectionStatus } from './back4app2';
import { BACK4APP_DOT_COM_DASHBOARD_URL, BACK4APP_DOT_COM_OLD_SITE_URL, BACK4APP_DOT_COM_SITE_URL, CONTAINERS_DASHBOARD_URL, PARSE_DASHBOARD_URL } from './settings';
import { initializeAmplitude, setUserForTracking } from './utils/amplitude';
import { initializeLogRocketSession } from './utils/logRocket';
import { initializeSolucxForm } from './utils/solucxWidget';
import { toast } from 'react-hot-toast';
import { ReactComponent as StatusErrorSVG } from './assets/images/status-error.svg';

interface AppState {
  user?: User;
  appsPlans?: AppPlan[];
  freePlanUsageHours?: number;
}

const INITIAL_STATE: AppState = {};

enum AppActionType {
  CHANGE_CONNECTION_STATUS,
  FINISH_LOADING_USER,
  FINISH_LOADING_APPS_PLANS,
  SET_FREE_PLAN_USAGE_HOURS
}

const finishLoadingUser = (user?: User) => ({
  type: AppActionType.FINISH_LOADING_USER,
  payload: {
    user
  }
} as const);

const finishLoadingAppsPlans = (appsPlans?: AppPlan[]) => ({
  type: AppActionType.FINISH_LOADING_APPS_PLANS,
  payload: {
    appsPlans
  }
} as const);

const setFreePlanUsageHours = (value?: number) => ({
  type: AppActionType.SET_FREE_PLAN_USAGE_HOURS,
  payload: {
    value
  }
} as const);

type AppAction = ReturnType<typeof finishLoadingUser> | ReturnType<typeof finishLoadingAppsPlans> | ReturnType<typeof setFreePlanUsageHours>;

const reducer = (state: AppState = INITIAL_STATE, action: AppAction): AppState => {
  switch (action.type) {
    case AppActionType.FINISH_LOADING_USER:
      return {
        ...state,
        user: action.payload.user
      };

    case AppActionType.FINISH_LOADING_APPS_PLANS:
      return {
        ...state,
        appsPlans: action.payload.appsPlans
      };
    case AppActionType.SET_FREE_PLAN_USAGE_HOURS:
      return {
        ...state,
        freePlanUsageHours: action.payload.value
      }
  }
};

const parseHref = (href: string) => {
  if (href.startsWith(window.location.origin)) {
    return href.replace(window.location.origin, '');
  } else {
    return href;
  }
}

const LinkImpl: ILink = ({ href, className, children }) => {
  href = parseHref(href);
  
  if (href.startsWith('http')) {
    return <a href={href} className={className}>
      {children}
    </a>;
  } else {
    return <Link to={href} className={className}>
      {children}
    </Link>;
  }
}

function App() {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { user, appsPlans, freePlanUsageHours } = state;
  const { isReconnecting } = useBack4app2ConnectionStatus();

  const location = useLocation();
  const pathname = location.pathname;

  const navigate = useNavigate();
  const push = useCallback((url: string) => {
    url = parseHref(url);

    if (url.startsWith('http')) {
      window.location.assign(url);
    } else {
      navigate(url);
    }
  }, [navigate]);
  const replace = useCallback((url: string) => {
    url = parseHref(url);

    if (url.startsWith('http')) {
      window.location.replace(url);
    } else {
      navigate(url, { replace: true });
    }
  }, [navigate]);

  const router = useMemo<IRouter>(() => ({
    pathname,
    push,
    replace
  }), [pathname, push, replace]);
    
  useEffect(() => {
    (async () => {
      let user;

      try {
        user = await back4app2.me();
        initializeAmplitude(user.username);
        setUserForTracking(user.id, user.username);
        initializeLogRocketSession(user.username);
        initializeSolucxForm(user);
      } catch (e) {
        if (e instanceof NetworkError) {
          console.error('network error when getting user', e);
        } else if (e instanceof AuthenticationError || e instanceof AuthorizationError) {
          console.error('authentication or authorization error when getting user', e);
        } else if (e instanceof Back4app2Error && e.code === 'NOT_CONTAINERS_BETA_USER') {
          console.error('not a containers beta user', e);
          window.location.replace(BACK4APP_DOT_COM_SITE_URL);

          return;
        } else {
          console.error('unexpected error when getting user', e);
        }

        window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);

        return;
      };

      dispatch(finishLoadingUser(user));

      let appsPlans;

      try {
        appsPlans = await back4app2.findAppsPlans();
      } catch (e) {
        if (e instanceof NetworkError) {
          console.error('network error when finding apps plans', e);
        } else if (e instanceof AuthenticationError || e instanceof AuthorizationError) {
          console.error('authentication or authorization error when finding apps plans', e);
          window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
        } else {
          console.error('unexpected error when finding apps plans', e);
        }

        return;
      };

      dispatch(finishLoadingAppsPlans(appsPlans));
    })();
  }, []);

  useEffect(
    () => {
      if (user) {
        const subscription = back4app2.subscribeToFreePlanUsageHours((error, snapshot) => {
          if (error) {
            if (error instanceof NetworkError) {
              console.error('network error on free plan usage hours subscription', error);
            } else if (error instanceof AuthenticationError || error instanceof AuthorizationError) {
              window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
            } else if (error instanceof NotFoundError) {
              console.error('free plan usage hours not found');
            } else {
              console.error('unexpected error loading free plan usage hours', error);
            }
          } else {
            dispatch(setFreePlanUsageHours(snapshot));
          }
        });
  
        return () => {
          subscription.unsubscribe();
          dispatch(setFreePlanUsageHours());
        }
      }
    },
    [user]
  );

  useEffect(() => {
    if (isReconnecting) {
      const toastId = toast.error('Cannot connect to our servers. Please check your internet. Reconnecting...', {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-tl-lg rounded-tr-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] text-sm",
        icon: <StatusErrorSVG width="24px" height="24px" className="animate-bounce-in flex-none" />,
        duration: Infinity,
        position: 'bottom-center',
      });

      return () => {
        toast.dismiss(toastId);
      };
    }
    
  }, [isReconnecting]);
  
  return (<>
    <div className="w-full h-full flex flex-col overflow-hidden">
      <Navbar
        user={(user && {
          ...user,
          containersBetaUser: true
        }) || undefined}
        overLimitAppsPlansCount={(appsPlans && appsPlans.filter(appPlan => appPlan.status === AppPlanStatus.OVER_LIMITS).length) || undefined}
        router={router}
        Link={LinkImpl}
        parseDashboardURL={PARSE_DASHBOARD_URL}
        containersDashboardURL={CONTAINERS_DASHBOARD_URL}
        back4appDotComSiteURL={BACK4APP_DOT_COM_SITE_URL}
        back4appDotComOldSiteURL={BACK4APP_DOT_COM_OLD_SITE_URL}
        back4appDotComDashboardURL={BACK4APP_DOT_COM_DASHBOARD_URL}
      />
      <Outlet context={{ user, freePlanUsageHours }} />
      <div
        className="absolute -left-[16.5625rem] -top-[9.5625rem] lg:-top-[23.4375rem] w-[36.8125rem] lg:w-[50.125rem] h-[34.6875rem] lg:h-[47.25rem] rounded-[374px] opacity-30 -z-[1]"
        style={{
          background: 'radial-gradient(48.2% 50% at 50% 50%, #3496EF 0%, rgba(15, 28, 50, 0) 100%)'
        }}
      >        
      </div>
    </div>    
  </>);
}

export default App;
